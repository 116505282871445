<template>
  <v-row class="px-10" dense>
    <v-col rows="12" md="6">
      <v-text-field v-model="company.company_name" :rules="[() => !!company.company_name || 'Este campo é obrigatório']"
        label="Nome*" placeholder="Digite o nome" outlined rounded dense />

    </v-col>
    <v-col rows="12" md="6">
      <v-text-field v-model="company.tranding_name" label="Nome Fantazia" placeholder="Digite o nome" outlined rounded
        dense />

    </v-col>

    <v-col cols="12" md="8">
      <v-text-field v-model="company.email" :rules="[
        () => !!company.email || 'Este campo é obrigatório',
        rules.email,
      ]" label="Email*" placeholder="exemplo@gmail.com" outlined rounded dense />

    </v-col>

    <v-col cols="12" md="4">
      <v-text-field v-model="company.phone_number" label="Telefone" v-mask="['(##) ####-####', '(##) #####-####']"
        placeholder="(xx)x.xxxx-xxxx" type="tel" outlined rounded dense />
    </v-col>

  </v-row>
</template>

<script>
export default {
  props: {
    company: Object
  },
  data: () => ({
    menu: false,
    activePicker: null,
    rules: {
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Insira um email válido";
      },
    },
    sellers: null
  }),
  computed: {
    birthday: function () {
      if (this.company.birthday != null) {
        let BR_dateFormat = this.company.birthday.split("-");
        BR_dateFormat = BR_dateFormat.reverse();
        BR_dateFormat = BR_dateFormat.join("/");
        return BR_dateFormat;
      } else {
        return "";
      }
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  },
  methods: {
    saveDate(date) {
      this.$refs.menu.save(date);
    },

  },

}
</script>